import React from "react"
import Layout from "../components/layout"
import Infonub from "../components/infonub"
import MarkContent from "../components/markContent"
import ColorContent from "../components/colorContent"
import Banner from "../components/banner"
import LowerBanner from "../components/lowerbanner"
import DoubleBanner from "../components/doubleBanner"
import AboutBanner from "../components/aboutBanner"
export default ({ location }) => ( 
    
    <Layout location={location}>

    <AboutBanner
    buttonText="shop gift cards" 
    pageLink=""
    classy="" 
    imageTwo="/images/About_Banner_Incentives.png" 
    image="/images/About_Banner_Shop.2.png"
    alt="3 gift cards next to a desk" 
    content={[
    <p>Online Shop</p>, 
    <p>Your one stop shop for <br/>physical and digital gift cards.</p>
    ]}
    contentTwo={[
    <p>Incentives Programs</p>, 
    <p>Explore our innovative Employee Rewards, Loyalty, and Group Travel Solutions.</p>
    ]}
    
    /> 
    


    <MarkContent classy="marketingContent"  
    content={[<strong>InComm InCentives </strong>,
       "builds value into every interaction through innovative loyalty, rewards, and recognition solutions. Consumers, employees, and businesses form meaningful relationships through our patented, award-winning technology." ]}/>
    
    <ColorContent 
    classy="marketingContent oneConnection" 
    content={[<p>One connection to our digital platforms and a world of redemption opportunities.</p>, 
    <p>We offer solutions for employee rewards and recognition, consumer loyalty, sales incentives, and health and wellness.</p>]}/>
    
    <div class="infonub services">
    

    <Infonub 
    picture="/images/icon-gift-cards@3x.png" 
    classy="smallIcon"
    alttext="a credit card" 
    title="Digital and Physical Gift Cards"
    alignment="left"
    content="The most convenient, versatile way to reward; our expansive options take gift cards to the next level. Physical or digital, branded or customized, single-load or reloadable — we've got you covered."/>


    <Infonub 
    picture="/images/icon-gift-tokens@3x.png" 
    classy="smallIcon"
     alttext="A Blue Present" 
     title={["Gift Tokens ", <sup>®</sup>]}
     alignment="left"
    content="Send a quick gift under $20, like a cup of coffee, to recognize all of life's moments. Wow your recipients with an interactive delivery that's enjoyable all on its own. "/>



    <Infonub picture="/images/icon-merchandise@3x.png" 
    classy="smallIcon"
     alttext="An Open Box" 
     title="Merchandise"
     alignment="left"
    content="Apparel, equipment, and accessories from the brands people love. Cash in on trendy swag, a new nine iron, or that perfect espresso machine — all viewable in your program's online marketplace. "/>
  

    <Infonub picture="/images/icon-travel@3x.png" 
    classy="smallIcon"
     alttext="Globe" 
     title="Travel"
     alignment="left"
    content="With our travel options, recipients can control their entire trip, from airfare and rental cars to five-star hotels and resorts. Our proprietary, full-service, white label booking engine makes planning the trip of a lifetime easy! "/>
    </div>

    <div class="infonub services">

    <Infonub picture="/images/icon-apple-integration@3x.png" 
    classy="smallIcon"
     alttext="Small red gear in a large White gear" 
     title="Apple Rewards Store"
     alignment="left"
    content="Offer access to the trendiest tech with our Apple Rewards Store integration. Whether it's iPhones or AirPods, the newest devices and accessories make the best rewards. "/>

    <Infonub picture="/images/icon-events-experiences@3x.png" 
    classy="smallIcon"
     alttext="A red rocket-ship" 
     title="Events and Experiences"
     alignment="left"
    content="For recipients who prefer the live experience, we've got you covered, too. Event packages, from the Super Bowl and Kentucky Derby, to Harley-Davidson rentals and fighter pilot flights — there's an experience for everyone."/>

   

    <Infonub 
     picture="/images/icon-points-engine@3x.png" 
     classy="smallIcon"
     alttext="gears in a cloud" 
     title="Points Engine"
     alignment="left"
     content="We make versatile rewards simple with a points system — giving recipients the freedom to manage their balance and choose their redemption pathway."/>

     <Infonub 
      picture="/images/icon-full-service-event-planning@3x.png" 
      classy="smallIcon"
      alttext="A clipboard with three red checks" 
      title="Full-Service Event Planning"
      alignment="left"
      content="Whether it is a sales meeting, product launch or incentive travel — we know what wows, and what works. Leave the details to us as we create a unique, memorable experience for your brand."/>

    </div>
    
    <LowerBanner buttonText="shop now" 
     classy="lowerbannerContent" 
     image="/images/lower-banner@2x.jpg" 
      textSize="lowerBanner" 
      alt="Two people looking at a computer" 
      content={[<p className="bannerTitle"><strong>Online Shop</strong></p>,
      <p>The InComm Incentives Shop is our simplest solution for purchasing gift cards, physical or digital, in bulk. Our Shop lets you select from a large offering of gift card brands and purchase them in only a few steps. Discount options are also available on select brands.

      </p>,

      <p>No contracts, no minimums, no IT involvement.</p>
     
      
      ]}/>


    </Layout>

)